/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import Link from "../components/link/link"

export default function PrivacyPolicy() {
  return (
    <Layout headerbg="bgGray">
      <Metadata title="Privacy Policy" />
      <Panel
        sx={{
          bg: "bgGray",
          py: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "2", "4"],
            gridColumnEnd: ["-1", "span 6"],
            pt: ["3rem", "unset"],
            fontSize: ["1.5rem", "1.6rem", "2rem"],
            lineHeight: [1.47, 1.5, 1.6],
          }}
        >
          <Themed.h1
            className="scaleText"
            sx={{
              "--max-font-size": [2.6, 3.8, 5.8],
            }}
          >
            Privacy Policy
          </Themed.h1>
          <Themed.p>Effective date: August 13, 2021</Themed.p>
        </div>
      </Panel>
      <Panel
        sx={{
          py: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "2", "4"],
            gridColumnEnd: ["-1", "span 6"],
            pt: ["3rem", "unset"],
          }}
        >
          <Themed.p>
            Moth+Flame ("us", "we", or "our") operates Promise and Ready VR
            applications (the "Service").
          </Themed.p>
          <Themed.p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </Themed.p>
          <Themed.p>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </Themed.p>
          <Themed.h2>Information Collection And Use</Themed.h2>
          <Themed.p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </Themed.p>
          <Themed.h3>Types of Data Collected</Themed.h3>
          <Themed.h4>Personal Data</Themed.h4>
          <Themed.p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </Themed.p>
          <Themed.h5>Cookies and Usage Data</Themed.h5>
          <Themed.h6>Usage Data</Themed.h6>
          <Themed.p>
            When you access the Service by or through a VR device, we may
            collect certain information automatically, including, but not
            limited to, the type of device you use, your device unique ID, the
            IP address of your VR device, your operating system, the type of
            mobile Internet browser you use, unique device identifiers and other
            diagnostic data ("Usage Data").
          </Themed.p>
          <Themed.h6>Tracking & Cookies Data</Themed.h6>
          <Themed.p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information.
          </Themed.p>
          <Themed.p>
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Tracking technologies also used
            are beacons, tags, and scripts to collect and track information and
            to improve and analyze our Service.
          </Themed.p>
          <Themed.p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </Themed.p>
          Examples of Cookies we use:
          <Themed.ul>
            <Themed.li>
              Session Cookies. We use Session Cookies to operate our Service.
            </Themed.li>
            <Themed.li>
              Preference Cookies. We use Preference Cookies to remember your
              preferences and various settings.
            </Themed.li>
            <Themed.li>
              Security Cookies. We use Security Cookies for security purposes.
            </Themed.li>
          </Themed.ul>
          <Themed.h2>Use of Data</Themed.h2>
          Moth+Flame uses the collected data for various purposes:
          <Themed.ul>
            <Themed.li>To provide and maintain the Service</Themed.li>
            <Themed.li>To notify you about changes to our Service</Themed.li>
            <Themed.li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </Themed.li>
            <Themed.li>To provide customer care and support</Themed.li>
            <Themed.li>
              To provide analysis or valuable information so that we can improve
              the Service
            </Themed.li>
            <Themed.li>To monitor the usage of the Service</Themed.li>
            <Themed.li>
              To detect, prevent and address technical issues
            </Themed.li>
          </Themed.ul>
          <Themed.h2>Transfer Of Data</Themed.h2>
          <Themed.p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </Themed.p>
          <Themed.p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </Themed.p>
          <Themed.p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </Themed.p>
          <Themed.p>
            Moth+Flame will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </Themed.p>
          <Themed.h2>Disclosure Of Data</Themed.h2>
          <Themed.h3>Legal Requirements</Themed.h3>
          Moth+Flame may disclose your Personal Data in the good faith belief
          that such action is necessary to:
          <Themed.ul>
            <Themed.li>To comply with a legal obligation</Themed.li>
            <Themed.li>
              To protect and defend the rights or property of Moth+Flame
            </Themed.li>
            <Themed.li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </Themed.li>
            <Themed.li>
              To protect the personal safety of users of the Service or the
              public
            </Themed.li>
            <Themed.li>To protect against legal liability</Themed.li>
          </Themed.ul>
          <Themed.h2>Security Of Data</Themed.h2>
          <Themed.p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </Themed.p>
          <Themed.h2>Service Providers</Themed.h2>
          <Themed.p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </Themed.p>
          <Themed.p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </Themed.p>
          <Themed.h2>Analytics</Themed.h2>
          <Themed.p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </Themed.p>
          <Themed.h2>Links To Other Sites</Themed.h2>
          <Themed.p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </Themed.p>
          <Themed.p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Themed.p>
          <Themed.h2>Children's Privacy</Themed.h2>
          <Themed.p>
            Our Service does not address anyone under the age of 18
            ("Children").
          </Themed.p>
          <Themed.p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </Themed.p>
          <Themed.h2>Changes To This Privacy Policy</Themed.h2>
          <Themed.p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </Themed.p>
          <Themed.p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </Themed.p>
          <Themed.p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Themed.p>
          <Themed.h2>Contact Us</Themed.h2>
          <Themed.p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </Themed.p>
          <Themed.p>
            By email:{" "}
            <Themed.a href="mailto:contact@mothandflamevr.com">
              contact@mothandflamevr.com
            </Themed.a>
          </Themed.p>
        </div>
      </Panel>
    </Layout>
  )
}
